import React, { FunctionComponent, useState } from "react";
import { Tabs, Typography, Tab, Box, useTheme, Theme, Grid } from "@material-ui/core";
import styled from "@emotion/styled";
import { RoundedImg, RoundPaper } from './styledComponents';
import ReactMarkdown from "react-markdown";

type TabInfo = {
    label: string,
    letter: string,
    title: string,
    content: string,
    tabImage: import("gatsby-source-filesystem").FileSystemNode
}

type TabBlockProps = {
    tabs: Array<TabInfo>
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const RoundImgShrunk = styled(RoundedImg)((props: {theme: Theme}) =>`
    ${props.theme.breakpoints.down("xs")} {
        height:175px;
    }
`);

const GridHeightMod = styled(Grid)((props: {theme: Theme}) =>`
    height: 350px;

    ${props.theme.breakpoints.down("xs")} {
        min-height: 500px;
        align-items: flex-start;
        justify-content: flex-start;
        align-content: flex-start;
    }
`);

const TabMinWidth = styled(Tab)`
    min-width: 45px;
`


const TabBlock: FunctionComponent<TabBlockProps> = (props) => {
    const [value, setValue] = useState(0);
    const theme = useTheme<Theme>();

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <RoundPaper elevation={8}>
            <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                
            >
                {props.tabs.map((t, idx) =>
                    <TabMinWidth key={t.letter} icon={<Typography variant="h2" color={value == idx ? "secondary" : "primary"}>{t.letter}</Typography>} label={t.label} {...a11yProps(idx)} />
                )}
            </Tabs>
            {props.tabs.map((t, idx) =>
                <Typography
                    component="div"
                    key={t.letter}
                    role="tabpanel"
                    hidden={value !== idx}
                    aria-labelledby={`tab-${idx}`}>
                    <GridHeightMod theme={theme} container alignItems="center" spacing={0}>
                        <Grid item sm={6} xs={12}>
                            <Box p={2}>
                                <RoundImgShrunk theme={theme} fluid={(t.tabImage.childImageSharp as any).fluid} />
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Box px={3}>
                                <Typography variant="h6" gutterBottom={true}>{t.title}</Typography>
                                <ReactMarkdown source={t.content} />
                            </Box>
                        </Grid>
                    </GridHeightMod>
                </Typography>
            )}
        </RoundPaper>);
}

export default TabBlock;