import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import Section from "../components/section";
import SEO from "../components/seo"
import HeroXL from "../components/heroXL";
import TabBlock from "../components/tabBlock";
import { Container, Box, useTheme, Theme, Typography, Grid, Button } from "@material-ui/core";
import styled from "@emotion/styled";
import UserPath from "../components/userPath";
import ReactMarkdown from "react-markdown";
import ContentBlock from "../components/contentBlock";
import EventSlider from "../components/eventSlider";
import TestimonialSlider from "../components/testimonialSlider";
import { RoundPaper } from "../components/styledComponents";
import BlogTeaser from "../components/blog-teaser";
import { ShapeArea, ShapeDown, ShapeStack } from '../components/shapes';
import MainLayout from "../layouts/mainLayout";
import LinkButton from "../components/link-button";

interface LocationProps {
  location: Location;
}

const LogoDiv = styled.div((props: { theme: Theme }) => `
  padding: 32px 120px;

  ${props.theme.breakpoints.down("sm")} {
    padding: 32px 0px;
  }
`);

const LogoTitle = styled(Typography)`
  padding-bottom: 16px;
`;

const NegMargin = styled(Container)((props: { theme: Theme }) =>`
  margin-top:-250px;
  position:relative;
  padding-bottom:64px;
  ${props.theme.breakpoints.down("xs")} {
      padding:4px;
  }
`);

const AppBox = styled(Box)((props: { theme: Theme }) => `
  padding: 0 32px 32px 32px;
  ${props.theme.breakpoints.down("sm")} {
    padding: 0 16px 16px 16px;
  }
`);

const InnerAppBox = styled(Box)((props: { theme: Theme }) => `
  padding: 48px;

  ${props.theme.breakpoints.down("sm")} {
    padding: 0px;
  }
`);

const ShapePadding = styled(Container)((props: { theme: Theme }) => `
  ${props.theme.breakpoints.up("md")} {
    padding: 0px 64px;
  }
`);

const AppImageContain = styled.div`
    margin: auto;
    max-width: 65%;
`;

const IndexPage: React.FC<LocationProps> = (props) => {
  const theme = useTheme<Theme>();

  const data = useStaticQuery(graphql`
    query {
      seo: homeJson(block: {eq: "seo" }) {
        title
        description
        image {
          childImageSharp {
              resize(width: 750, height: 300, cropFocus: ENTROPY) {
                  src
                  width
                  height
              }
          }
        }
      }
      header: homeJson(block: {eq: "header"}) {
        title
        tagline
        belowTitle
        belowTagline
        ctaText
        ctaUrl
        image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160, maxHeight: 3120, cropFocus: ATTENTION) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      neuro: homeJson(block: {eq: "neuro"}) {
        tabs {
          content
          label
          letter
          title
          tabImage {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      brainInitiative: homeJson(block: {eq: "brain-initiative"}) {
        title
        content
        logoImage {
          childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
          }
        }
      }
      neuroAcademy: homeJson(block: {eq: "neuroAcademy"}) {
        title
        content
        logoImage {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight:150, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        choices {
          cta
          ctaUrl
          title
          description
          choiceImage {
            childImageSharp {
              fluid(maxWidth: 615, maxHeight: 300, cropFocus: CENTER ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      brainXQ: homeJson(block: {eq: "brainXQ" }) {
        title
        content
        appTitle
        appContent
        webAppCTA
        webAppUrl
        nativeAppTitle
        showAppDownloads
        downloads {
          link
          alt
          imgSrc
          key
        }
        logoImage {
          childImageSharp {
            fluid(maxWidth: 325, maxHeight: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        appTeaserImage {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      events: homeJson(block: {eq: "events" }) {
        title
        blogPosts
        events {
          title
          content
          ctaButton
          ctaLink
          image {
            childImageSharp {
              fluid(maxHeight: 375, maxWidth:600, cropFocus: ENTROPY) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      },
      testimonials: homeJson(block: {eq: "testimonial" }) {
        quotes {
          quote
          author
        }
        title
        logos {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      blogPosts: allMarkdownRemark (
        sort: { order: DESC, fields: [frontmatter___publish_date] }
        filter: {
          frontmatter: {
            published: { eq: true }
          }
        }
        limit: 5
      ){
        edges {
          node {
            excerpt(pruneLength: 400)
            fields {
              slug
            }
            frontmatter {
              title
              tags
              publish_date(formatString: "MMMM D, YYYY")
              primary_image {
                  childImageSharp {
                      fluid(maxWidth: 350, maxHeight: 350, cropFocus: ENTROPY) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <MainLayout transparentNav>
      <SEO {...data.seo} location={props.location} />

      <HeroXL {...data.header} overlayColor={theme.palette.primary.dark} >
        <div style={{ height: `250px` }} />
      </HeroXL>

      <NegMargin theme={theme} maxWidth="md">
        <TabBlock {...data.neuro} />
      </NegMargin>

      
       {/* <Section>
        <Container>
          <ShapePadding theme={theme} maxWidth="md">
            <ShapeArea>
              <ShapeStack zIndex={-1}>
                <ShapeDown color={theme.palette.primary.main} rotate={195} scale={.95} opacity={35} translateX={50} translateY={305} />
                <ShapeDown color={theme.palette.secondary.main} rotate={12} opacity={35} translateY={-125} translateX={-50} />
              </ShapeStack>
            </ShapeArea>
          </ShapePadding>
          <Typography variant="h3" component="h2" align="center" gutterBottom>{data.events.title}</Typography>
          <EventSlider speed={5000} events={data.events.events} blogs={data.blogPosts.edges.map(e => e.node).splice(0, data.events.blogPosts)} />
        </Container>
      </Section> */}

      <Section tightTop> 
        <Container maxWidth="md">
          <ContentBlock maxWidth={600} {...data.brainInitiative} />
          <Box display="flex" justifyContent="center">
          <LinkButton variant="contained" color="primary" url="/about">
            <Typography variant="button">More About Sherzai MD</Typography>
          </LinkButton>
          </Box>
        </Container>
      </Section>

      <Section bgcolor={theme.palette.primary.dark}>
        <Container maxWidth="md">
          <RoundPaper elevation={8}>
            <ContentBlock maxWidth={375} {...data.neuroAcademy} />
            <UserPath choices={data.neuroAcademy.choices} />
          </RoundPaper>
        </Container>
      </Section>

      <Section>
        <Container maxWidth="md">
          <RoundPaper elevation={6}>
            <ContentBlock {...data.brainXQ} />
            <AppBox theme={theme}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Box pb={4}>
                    <ShapeArea marginTop={0} marginBottom={0}>
                      <ShapeStack top={25}>
                        <ShapeDown color={theme.palette.primary.light} rotate={135} opacity={15} translateX={-50} translateY={150} />
                        <ShapeDown color={theme.palette.secondary.main} rotate={-80} scale={1.05} opacity={80} translateX={-425} translateY={65} />
                        <ShapeDown color={theme.palette.secondary.light} rotate={120} opacity={75} translateX={200} translateY={0} />
                      </ShapeStack>
                    </ShapeArea>
                    <AppImageContain>
                      <Img fluid={data.brainXQ.appTeaserImage.childImageSharp.fluid} />
                    </AppImageContain>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InnerAppBox theme={theme} px={1}>
                    <Typography variant="h5" component="h3">{data.brainXQ.appTitle}</Typography>
                    <ReactMarkdown>{data.brainXQ.appContent}</ReactMarkdown>
                    {data.brainXQ.webAppCTA &&
                      <Box textAlign="center" py={2}>
                        <Button variant="contained" size="large" color="secondary" href={data.brainXQ.webAppUrl} target="__blank">{data.brainXQ.webAppCTA}</Button>
                      </Box>
                    }
                    {data.brainXQ.nativeAppTitle &&
                      <Typography variant="h6" align="center">{data.brainXQ.nativeAppTitle}</Typography>
                    }
                    {data.brainXQ.showAppDownloads && 
                    <Box pt={1} display="flex" alignItems="center" justifyContent="center">
                      {data.brainXQ.downloads.map(d =>
                        <a key={d.key} href={d.link} target="_blank" rel="noopener">
                          <img height={d.key == "apple" ? 40 : 59} alt={d.alt} src={d.imgSrc} />
                        </a>
                      )}
                    </Box>}
                  </InnerAppBox>
                </Grid>
              </Grid>
            </AppBox>
          </RoundPaper>
        </Container>
      </Section>

      <Section bgcolor={theme.palette.secondary.light}>
        <Container>
          <TestimonialSlider quotes={data.testimonials.quotes} ></TestimonialSlider>
          <LogoDiv theme={theme}>
            <LogoTitle variant="h3" align="center" gutterBottom>{data.testimonials.title}</LogoTitle>
            <Grid container alignItems="center" justify="space-around">
              {data.testimonials.logos.map((l, idx) =>
                <Grid key={idx} item xs={12} sm={4} md={3}>
                  <Box p={3}>
                    <Img fluid={l.image.childImageSharp.fluid} alt={l.alt} />
                  </Box>
                </Grid>
              )}
            </Grid>
          </LogoDiv>
        </Container>
      </Section>

      <BlogTeaser />
    </MainLayout>
  )
}

export default IndexPage
