import React, { FunctionComponent, useState } from "react";
import VisibilitySensor from 'react-visibility-sensor';
import Carousel from 'nuka-carousel';

interface AutoPlaySliderProps {
    speed?: number;
}

const AutoPlaySlider : FunctionComponent<AutoPlaySliderProps> = (props) => {
    const [visible, setVisible] = useState(false);
    var speed = props.speed || 5000;

    return (
        <VisibilitySensor onChange={setVisible} intervalDelay={250}>
            <Carousel wrapAround autoplayInterval={speed} slidesToShow={1} autoplay={visible}
                renderCenterLeftControls={null}
                renderCenterRightControls={null}
                >
                {props.children}
            </Carousel>
        </VisibilitySensor>
    )
}

export default AutoPlaySlider;