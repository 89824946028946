import React, {FunctionComponent } from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { getBackgroundImageStack, HeroImage, HeroTagLine } from './hero';
import { TextOverlayType } from './styledComponents';
import LinkButton from './link-button';

type HeroXLProps = {
    image: import("gatsby-source-filesystem").FileSystemNode;
    title: string,  
    tagline?: string,
    belowTitle?: string,
    belowTagline?: string,
    overlayColor: string,
    ctaText: string,
    ctaUrl: string
};

const bounce = keyframes`
0%, 100% {transform: translateY(0);}
50% {transform: translateY(-15px);}
`;

const Scroller = styled.div`
align-self: flex-end;
width:100%;
text-align:center;
margin-bottom: 40px;
animation: ${bounce} 1s infinite;
`;

const SizedIcon = styled(FontAwesomeIcon)`
width: 0.875em;
font-size: 4em;
`;

const HeroXL : FunctionComponent<HeroXLProps> = (props) => {
   
    return (
    <HeroImage critical={true} fadeIn={false} fluid={getBackgroundImageStack((props.image.childImageSharp as any).fluid, props.overlayColor)} Tag="section">
        <>
            <Box display="flex" flexWrap="wrap" alignItems="center" height="100vh">
                <Container>
                    <TextOverlayType shadowColor={props.overlayColor} variant="h1" align="center" gutterBottom={true}>
                        {props.title}
                    </TextOverlayType>
                    {props.tagline && <HeroTagLine shadowColor={props.overlayColor} variant="h3" component="h2" align="center">
                        {props.tagline}
                    </HeroTagLine>}
                    {props.ctaText && 
                        <Box display="flex" justifyContent="center" pt={4}>
                            <LinkButton variant="contained" color="secondary" size="large"
                                url={props.ctaUrl} >
                                <Typography align="center" variant="h5" component="span">{props.ctaText}</Typography>
                            </LinkButton>
                        </Box>
                    }
                </Container>
                <Scroller>
                    <AnchorLink href="#more">
                        <SizedIcon icon={faChevronDown} color="white" />
                    </AnchorLink>
                </Scroller>
            </Box>
            <Box py={10} id="more">
                {(props.belowTitle || props.belowTagline) && <Container>
                    {props.belowTitle && <TextOverlayType shadowColor={props.overlayColor} variant="h3" align="center" gutterBottom={true}>
                        {props.belowTitle}
                        </TextOverlayType>}
                    {props.belowTagline && <HeroTagLine shadowColor={props.overlayColor} variant="h4" component="span" align="center">
                        {props.belowTagline}
                    </HeroTagLine>}
                </Container>}
                {props.children}
            </Box>
        </>
    </HeroImage>
    )
}

export default HeroXL;