import React, { FunctionComponent } from "react";
import { Box, Typography, useTheme, Theme, Grid, Container } from "@material-ui/core";
import AutoPlaySlider from "./autoPlaySlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { ThemeWithAccent } from "../layouts/theme";

interface SlideContent {
    quote: string,
    author: string
}

interface TestimonialSliderProps {
    speed?: number,
    quotes: Array<SlideContent>
}

const TestimonialSlider: FunctionComponent<TestimonialSliderProps> = (props) => {
    const theme = useTheme<ThemeWithAccent>();

    return (
        <AutoPlaySlider speed={props.speed}>
            {props.quotes.map((x, idx) =>
                <Box pb={4}  key={idx}>
                    <Container maxWidth="md">
                        <Grid container>
                            <Grid item container justify="center" alignItems="flex-start" xs={12} md={2}>
                                <Box py={2}>
                                    <FontAwesomeIcon icon={faQuoteLeft} size="3x" color={theme.accent.main} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="h4" align="center" gutterBottom>{x.quote}</Typography>
                                <Typography variant="subtitle1" align="center">-- {x.author}</Typography>
                            </Grid>
                            <Grid item container justify="center" alignItems="flex-start" xs={12} md={2}>
                                <Box py={2}>
                                    <FontAwesomeIcon icon={faQuoteRight} size="3x" color={theme.accent.main} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            )}
        </AutoPlaySlider>
    );
}

export default TestimonialSlider;