import React, { FunctionComponent } from "react";
import { ButtonBase, useTheme, Theme, Box, Typography, Grid } from "@material-ui/core";
import BackgroundImage from "gatsby-background-image";
import styled from "@emotion/styled";
import { navigate } from "gatsby";
import { hexToRgb } from "./hero";


type Choice = {
    choiceImage: import("gatsby-source-filesystem").FileSystemNode;
    title: string,
    description: string,
    cta: string,
    ctaUrl: string
}

type UserPathProps = {
    choices: Array<Choice>
}

const getHex = (o: number) => Math.floor((Math.min(o, 100) / 100) * 255).toString(16).toUpperCase();

const ImageCta = styled.div((props: { theme: Theme }) => `
    position:relative;
    padding:8px;
    border-radius: 5px;
    border: 3px solid transparent;
    width:250px;
    margin:0px auto;

    ${props.theme.breakpoints.down("md")} {
        border: 3px solid ${props.theme.palette.secondary.light};
    }
    
`);

const OuterContainer = styled(ButtonBase)((props: { theme: Theme }) => `
    width: 100%;
    height: 100%;
    
    &:hover {
        & ${ImageCta} {
            border: 3px solid ${props.theme.palette.secondary.light};
        }
    }
`);

const Background = styled(BackgroundImage, {
    shouldForwardProp: prop => prop !== "first" && prop != "theme"
})((props: {first: boolean, theme: Theme}) =>`
    width:100%;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:center;

    &:before, &:after 
    { 
        border-radius: ${props.first ? '0 0 0 16px' : '0 0 16px 0'}; 
        ${props.theme.breakpoints.down("xs")} {
            border-radius: ${props.first ? '0px' : '0 0 16px 16px'}; 
        }
    }
`);

const BottomMark = styled.div((props: { theme: Theme }) => `
    margin:auto;
    height:3px;
    width:18px;
    background-color: ${props.theme.palette.secondary.light};
    position:absolute;
    bottom:-3px;
    left: calc(50% - 9px);
`);

const CtaText = styled(Typography)((props: { theme: Theme }) => {
    const textShadowColor = props.theme.palette.primary.light;
    return `
        text-shadow: text-shadow: 1px 1px 25px ${textShadowColor}4D
        width:100%;
        color: ${props.theme.palette.primary.contrastText};`
});

const CtaButtonText = styled(CtaText)`
    text-transform:uppercase;
`;


const UserPath: FunctionComponent<UserPathProps> = (props) => {
    const theme = useTheme<Theme>();

    const rgb = hexToRgb(theme.palette.primary.light);
    const backgroundImageStack = (img) => [
        img,
        `linear-gradient(rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, .6), rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, .6))`
    ].reverse();

    return (
        <Grid container>
            {props.choices.map((c, i) =>
                <Grid key={i} item xs={12} sm={6}>
                    <OuterContainer theme={theme} onClick={() => navigate(c.ctaUrl)}
                        focusRipple
                    >
                        <Background first={i == 0} theme={theme} fluid={backgroundImageStack((c.choiceImage.childImageSharp as any).fluid)}>
                            <Box py={4} minHeight={350} display="flex" flexDirection="column" justifyContent="space-around">
                                <CtaText theme={theme} variant="h4">{c.title}</CtaText>
                                <Box px={2} py={4} justifySelf="center">
                                    <CtaText theme={theme} variant="h5" component="span">{c.description}</CtaText>
                                </Box>
                                <ImageCta theme={theme}>
                                    <CtaButtonText theme={theme} variant="h4" component="span">{c.cta}</CtaButtonText>
                                    <BottomMark theme={theme} />
                                </ImageCta>
                            </Box>
                        </Background>
                    </OuterContainer>
                </Grid>
            )}
        </Grid>
    )
}

export default UserPath;